<template>
  <v-form v-bind="$attrs" v-on="$listeners" ref="form">
    <v-container fluid>
      <!-- Workflow project template -->
      <v-row dense>
        <v-col>
          <v-text-field
            dense
            readonly
            v-model="templateName"
            label="Project Template"
            :rules="projectTemplateRules"
            @click="onSelectWorkflowTemplate"
          >
            <template v-slot:append>
              <base-tooltip-button
                :fab="true"
                :small="true"
                :disabled="!browseProjectTemplates"
                @click="onSelectWorkflowTemplate"
              >
                <template v-slot:icon-name
                  >"
                  {{ iconMore }}
                </template>
                <template v-slot:toolTip>
                  {{ toolTipSelectProjectTemplate }}
                </template>
              </base-tooltip-button>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Project Name & Manager -->
      <v-row dense>
        <!-- "Project Name -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="projectName"
            label="Project Name"
            :rules="projectNameRules"
            dense
          ></v-text-field>
        </v-col>

        <!-- "project Manager -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="projectManager"
            label="Project Manager"
            readonly
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- project Comment-->
      <v-row dense>
        <v-col>
          <v-textarea
            v-model="projectComment"
            label="Comments"
            dense
            outlined
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- project template tasks-->
      <v-row dense v-if="visibleProjectTemplateTasks">
        <v-col>
          <new-project-tasks
            :project="project"
            :valid-tasks="validTasks"
          ></new-project-tasks>
        </v-col>
      </v-row>

      <!-- Run only one project at a time -->
      <v-row dense>
        <v-col cols="12" md="6">
          <v-checkbox
            dense
            v-model="runOnlyOneProject"
            label="Run only one project at a time"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- project template browser -->
      <project-template-browser
        :visible="visibleProjectTemplateBrowser"
        :perform-action="performSelectProjectTemplate"
        :select-template="template"
        @close="closeProjectTemplateBrowser"
      ></project-template-browser>
    </v-container>
  </v-form>
</template>

<script>
// design
import { iconDotsHorizontal } from "@/design/icon/iconConst";

// model
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";
import {
  formatProjectTemplateActionName,
  projectTemplateLabel,
  projectTemplateOperation
} from "@/model/workflow/project/projectTemplateModel";
import {
  projectNameRules,
  projectTemplateRules
} from "@/model/workflow/project/projectModel";
import { eventNames } from "@/model/common/events/eventConst";
import { isValidTaskEntries } from "@/model/workflow/task/taskModel";

export default {
  name: "FormNewProject",
  inheritAttrs: false,
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    ProjectTemplateBrowser: () =>
      import("@/components/project/template/ProjectTemplateBrowser"),
    NewProjectTasks: () =>
      import("@/components/project/new/NewProjectTasks.vue")
  },
  data() {
    return {
      iconMore: iconDotsHorizontal,
      visibleProjectTemplateBrowser: false,
      validTasks: true
    };
  },
  props: {
    /**
     * @type {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number}}
     */
    template: undefined,

    /**
     * new project
     * @type {{recordId: number, projectName: string, comment: string, templateId: number, runOnlyOneProject: boolean}}
     */
    project: undefined,

    /**
     * browse Project Templates
     */
    browseProjectTemplates: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * project Template Name
     */
    templateName: {
      get() {
        return this.template?.name ?? "";
      },
      set(value) {
        if (this.template) {
          this.template.name = value;
        }
      }
    },

    /**
     * project Name
     */
    projectName: {
      get() {
        return this.project?.projectName ?? "";
      },
      set(value) {
        if (this.project) {
          this.project.projectName = value;
        }
      }
    },

    /**
     * project Manager
     */
    projectManager: {
      get() {
        return this.project?.projectManager ?? "";
      },
      set(value) {
        if (this.project) {
          this.project.projectManager = value;
        }
      }
    },

    /**
     * project Comment
     */
    projectComment: {
      get() {
        return this.project?.comment ?? "";
      },
      set(value) {
        if (this.project) {
          this.project.comment = value;
        }
      }
    },

    /**
     * run Only One Project at a time
     */
    runOnlyOneProject: {
      get() {
        return this.project?.runOnlyOneProject ?? false;
      },
      set(value) {
        if (this.project) {
          this.project.runOnlyOneProject = value;
        }
      }
    },

    /**
     * visible Project Template Tasks
     * @return {boolean} true if visible Project Template Tasks
     */
    visibleProjectTemplateTasks() {
      return (this.template?.id ?? -1) > 0;
    },

    /**
     * toolTip Select Project Template
     * @return {string}
     */
    toolTipSelectProjectTemplate() {
      return `Select ${projectTemplateLabel}`;
    },

    /**
     * project Name Rules
     * @return {*[]|(function(*): *)[]}
     */
    projectNameRules() {
      return projectNameRules();
    },

    /**
     * project Template Rules
     * @return {(function(*): *)[]|*[]}
     */
    projectTemplateRules() {
      return projectTemplateRules(this.project?.templateId ?? -1);
    },

    userTasks() {
      return this.project?.userTasks ?? [];
    }
  },
  methods: {
    /**
     * handle event on Select Workflow Template
     */
    onSelectWorkflowTemplate() {
      if (this.browseProjectTemplates) {
        this.visibleProjectTemplateBrowser = true;
      }
    },

    /**
     * close Workflow Template Browser
     */
    closeProjectTemplateBrowser() {
      this.visibleProjectTemplateBrowser = false;
    },

    /**
     * perform Select Project Template
     * @param {number} templateId selected Project Template id
     * @return {{type: string, message: string, outcome: string}}
     */
    performSelectProjectTemplate(templateId) {
      try {
        console.log(
          `${this.$options.name}.performSelectProjectTemplate() selected templateId:`,
          templateId
        );

        // this.projectNewTemplate = projectNewTemplate;
        //
        // storeAgendaTemplateId(this.projectNewTemplate?.id ?? -1);
        //
        //this.$emit(eventNames.agendaTemplateChanged, templateId);
        this.$emit("projectTemplateChanged", templateId);

        const action = formatProjectTemplateActionName(
          projectTemplateOperation.select
        );

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e.toString());
      }
    },

    /**
     * will validate all inputs and return if they are all valid or not.
     * @return {boolean} returns true if they are all valid, else returns false
     */
    validate() {
      return this.$refs.form.validate();
    },

    /**
     * will clear all inputs and reset their validation errors
     */
    reset() {
      this.$refs.form.reset();
    },

    /**
     * reset only input validation and not alter their state
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    validateTaskEntries(task) {
      //this.validateTaskData();
      return this.isValidTaskEntries(task);
    },

    /**
     * Validate Task Data (custom validator)
     * Expand on Demand
     */
    validateTaskData() {
      try {
        console.log(`validateTaskData()`);
        if (this.project) {
          this.validTasks = true;
          this.userTasks.forEach(task => {
            if (!isValidTaskEntries(task)) {
              this.validTasks = false;
            }
          });
        } else {
          this.validTasks = false;
        }
        this.$emit(eventNames.taskValidation, this.validTasks);
      } catch (e) {
        console.error(e);
      }
    }
  },

  mounted() {
    /**
     * Apply Validation when a task loaded
     */
    this.validateTaskData();
  },

  watch: {
    /**
     * Watch userTasks if project Task Data Changed
     */
    userTasks: {
      handler() {
        console.log(`watch userTasks changed`);
        this.validateTaskData();
      },
      deep: true
    }
  }
};
</script>
